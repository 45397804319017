import React from "react"
import "@popperjs/core/dist/umd/popper.min"
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.min"
import "./src/global-styles/app.scss"
import "@fortawesome/fontawesome-svg-core/styles.css"
import { config } from "@fortawesome/fontawesome-svg-core"
import { OverlayProvider } from "./src/context/overlay-context"
import { HierarchyProvider } from "./src/context/hierarchy-context"
// Prevent fontawesome from dynamically adding its css since we did it manually above
if (typeof window === 'undefined') config.autoAddCss = false;
else config.autoAddCss = true;

export const wrapRootElement = ({ element }) => (
  <HierarchyProvider>
    <OverlayProvider>
      {element}
    </OverlayProvider>
  </HierarchyProvider>
)