import React, { createContext, useContext, useState } from 'react'

const OverlayContext = createContext()

const OverlayProvider = (props) => {
  const [overlay, setOverlay] = useState(false)

  return <OverlayContext.Provider value={{ overlay, setOverlay }} {...props} />
}

const useOverlay = () => {
  return useContext(OverlayContext) || {}
}

export { OverlayProvider, useOverlay }